<template>
  <section id="join-one-on-one">
    <h1 class="title">Join <span class="accent--text">Scheduling</span></h1>
  </section>
</template>

<script>
export default {
  name: "JoinOneOnOne"
};
</script>
